import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Navigation, Autoplay } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

import { Swiper, SwiperSlide } from "swiper/react"

const Testimonials = () => {
  const result = useStaticQuery(graphql`
    query queryTestimonials {
      allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "Testimonials" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              testimonials
              title
            }
          }
        }
      }
    }
  `)

  const testimonials = result.allMarkdownRemark.edges[0].node.frontmatter.testimonials;

  return (
    <Swiper
      navigation={true}
      speed={500}
      spaceBetween={30}
      autoplay={{
        delay: 3000,
      }}
      modules={[Navigation, Autoplay]}
    >
      {testimonials && testimonials.map((testimonial, index) => (
      <SwiperSlide className="relative" key={index}>
        <p className="text-lg text-primary leading-1.625 px-50px">{testimonial}</p>
      </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Testimonials
