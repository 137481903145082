import React, { useRef } from "react"
import useIntersectionObserver from "@react-hook/intersection-observer"

const Video = ({ className, videoSrcURL, videoTitle, ...props }) => {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)

  if (isIntersecting && !lockRef.current) {
    lockRef.current = true
  }

  return (
    <div
      className="video"
      className={className}
      ref={containerRef}
      style={{ overflow: "hidden", position: "relative" }}
    >
      {lockRef.current && (
        <iframe
          src={videoSrcURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          loading="lazy"
          className="absolute inset-0 w-full h-full"
        />
      )}
    </div>
  )
}

export default Video
